@import "../sass/variaveis";
@import "../sass/utilities/reset";

@import "../sass/mixins"; //MIXINS
@import "../sass/utilities/animation";
@import "../sass/maska_mixins.scss";
//components
@import "../sass/components/body"; //BODY
@import "../sass/components/menu/menu";
@import "../sass/components/footer";
@import "../sass/components/login";
@import "../sass/components/tabs";

// STYLE SALEBRAND
// @import "../sass/sections/salebrand/salebrand_style";



// PÁGINAS

@import "../sass/sections/maska/maska-parceiros.scss";
@import "../sass/sections/maska/maska-contato.scss";
@import "../sass/sections/maska/maska-footer.scss";

main {
  overflow: hidden;
}

@keyframes arrowAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(5px);
  }
}

.page_home {
  @import "../sass/sections/maska/home/maska-home-banner.scss";
  @import "../sass/sections/maska/home/maska-home-como-funciona.scss";
  @import "../sass/sections/maska/home/maska-home-solucoes.scss";
  @import "../sass/sections/maska/home/maska-home-sua-marca.scss";
}

.body_ead {
  @import "../sass/sections/maska/ead/maska-ead-banner.scss";
  @import "../sass/sections/maska/ead/maska-software-ead.scss";
  @import "../sass/sections/maska/ead/maska-diferenciais-ead.scss";
  @import "../sass/sections/maska/ead/maska-funcionalidades-ead.scss";
  @import "../sass/sections/maska/ead/maska-como-funciona-ead.scss";
}

.body_neat {
  @import "../sass/sections/maska/neat/maska-neat-banner.scss";
  @import "../sass/sections/maska/neat/maska-hub-neat.scss";
  @import "../sass/sections/maska/neat/maska-diferenciais-neat.scss";
  @import "../sass/sections/maska/neat/maska-funcionalidades-neat.scss";
  @import "../sass/sections/maska/neat/maska-como-funciona-neat.scss";
}

.body_subscriptions {
  @import "../sass/sections/maska/subscriptions/maska-subscriptions-banner.scss";
  @import "../sass/sections/maska/subscriptions/maska-personaliza-subscriptions.scss";
  @import "../sass/sections/maska/subscriptions/maska-diferenciais-subscriptions.scss";
  @import "../sass/sections/maska/subscriptions/maska-funcionalidades-subscriptions.scss";
  @import "../sass/sections/maska/subscriptions/maska-como-funciona-subscriptions.scss";
}